<template>
  <div class="reward-history">
    <section class="body">
      <div class="card">
        <div class="card-header justify-content-between table-field">
          <h4 class="my-0">Reward history</h4>
        </div>
        <!-- <div class=""> -->
        <b-table
          striped
          hover
          selectable
          responsive
          show-empty
          :items="filteredSingleDriverComplianceRewardHistory"
          :fields="fields"
          :current-page="currentPage"
          :busy="fetchingSingleDriverComplianceRewardHistory"
        >
          <template #table-busy>
            <div
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <div class="spinner-border" role="status"></div>
              <p class="text-center mt-2"><strong>Loading...</strong></p>
            </div>
          </template>
          <template #empty>
            <div class="no-results">
              <img src="@/assets/img/no-result-found.svg" />
              <p class="text-center text-secondary">No record found</p>
            </div>
          </template>
          <template #cell(date)="data">
            <p class="font-weight-bold my-0 table-field">
              {{ formatDate(data.item.date) }}
            </p>
          </template>
          <template #cell(time)="data">
            <p class="my-0 table-field">
              {{ formatTime(data.item.time) }}
            </p>
          </template>
          <template #cell(description)="data">
            <p class="my-0 table-field">
              {{ data.item.description }}
            </p>
          </template>
          <template #cell(point)="data">
            <p
              class="font-weight-bold my-0 table-field"
              :class="{
                'text-danger': data.item.action === 'penalty',
                'text-success': data.item.action === 'reward',
              }"
            >
              {{ data.item.point }} points
            </p>
          </template>
          <template #cell(value)="data">
            <p class="font-weight-bold my-0 table-field">
              {{ data.item.value ? data.item.value : 'N/A' }}
            </p>
          </template>
        </b-table>
      </div>
    </section>
    <div class="pageFooter" v-if="!fetchingEvents">
      <section>
        <p class="desc">
          Showing {{ paginationStartIndex }} - {{ paginationEndIndex }} of
          {{ totalRecords }}
        </p>
      </section>
      <section>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRecords"
          :per-page="perPage"
        ></b-pagination>
      </section>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import { mapActions } from 'vuex'

export default {
  created() {
    this.getDriverComplianceRewardHistory({
      userId: Number(this.$route.params.driverId),
      userType: 'driver',
    })
  },
  data() {
    return {
      fields: [
        {
          key: 'date',
          label: 'DateAdded',
        },
        {
          key: 'time',
          label: 'Time',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'point',
          label: 'Point',
        },
        {
          key: 'value',
          label: 'Point Value',
        },
      ],
      perPage: 10,
      currentPage: 1,
      totalRecords: 0,
      pageSize: 0,
      fetchingEvents: false,
    }
  },
  computed: {
    paginationStartIndex() {
      return this.currentPage * this.perPage - (this.perPage - 1)
    },
    paginationEndIndex() {
      return this.paginationStartIndex - 1 + this.pageSize
    },
    singleDriverComplianceRewardHistory() {
      return this.$store.state.rewardPoints.singleDriverComplianceRewardHistory
    },
    filteredSingleDriverComplianceRewardHistory() {
      return this.singleDriverComplianceRewardHistory?.map((data) => ({
        id: data.id,
        date: data.created_at,
        time: data.created_at,
        description: data.description,
        point: data.points,
        value: data.value,
        action: data.action,
      }))
    },
    fetchingSingleDriverComplianceRewardHistory() {
      return this.$store.state.rewardPoints
        .fetchingSingleDriverComplianceRewardHistory
    },
  },
  methods: {
    ...mapActions('rewardPoints', ['getSingleDriverComplianceRewardHistory']),

    async getDriverComplianceRewardHistory(data) {
      try {
        const res = await this.getSingleDriverComplianceRewardHistory(data)

        if ([201, 200].includes(res.status)) {
          this.currentPage = res.data.metadata.current_page
          this.totalRecords = res.data.metadata.total
          this.totalPages = res.data.metadata.total_pages
          this.pageSize = res.data.data.length
        }
      } catch (error) {
        const msg =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'Failed to query event'
        this.$swal().showValidationMessage(msg)
      }
    },

    formatDate(d) {
      return moment(d).format('MMM Do YYYY')
    },

    formatTime(d) {
      return moment(d).format('h:mm a')
    },
  },
}
</script>
<style lang="scss" scoped>
.reward-history {
  overflow: auto;
  & .body {
    & .card {
      & .user-link {
        color: #4848ed !important;
        font-weight: 500;
        letter-spacing: -0.284px;
        text-decoration-line: underline;
      }

      & .table-field {
        padding-top: 9px;
        padding-bottom: 9px;
        height: 100%;
        vertical-align: middle;
      }
    }
  }
}

.no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 50px 0;
  img {
    height: 180px;
  }
}

.pageFooter {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 94px;

  & .desc {
    font-size: 14px;
    font-weight: 500;
    color: #131615;
  }
}
</style>
